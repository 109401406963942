import Cache from "./../cache";

let assinatura_selecionada_cache = new Cache('assinatura_selecionada');

let INITIAL_STATE = {
    login:  "https://acesso.estadao.com.br/login",
    logout: "https://acesso.estadao.com.br/login/logout",
    meuEstadaoUrl:  "https://meu.estadao.com.br",
    estadaoUrl: "https://www.estadao.com.br",
    assineURL:  `https://assine.estadao.com.br/landingpage?btn=menulateral&utm_source=meu_estadao_menulateral&utm_medium=meu_estadao_menulateral&utm_campaign=meu_estadao_menulateral&utm_content=meu_estadao_menulateral&referrer_url=${window.location.href}`,
    showSubscriptions:  true,
    idIframe: 'menu-meu-estadao-lateral',
    assinatura_selecionada: assinatura_selecionada_cache.get(43200),
    meuEstadaoMenu: {
        dados_cadastrais: "https://meu.estadao.com.br/meus-dados",
        alterar_senha: "https://meu.estadao.com.br/alterar-senha",
        newsletter: "https://meu.estadao.com.br/newsletter",
        historico_de_pagamento: "https://meu.estadao.com.br/historico-de-pagamentos",
        incentiva: "https://meu.estadao.com.br",
        podcasts: "https://eldorado.estadao.com.br/podcasts",
        tv: "https://tv.estadao.com.br/",
        quiz: "https://busca.estadao.com.br/?q=quiz",
        static: false,
        extra: {}
    },
    onLoadMenu:() => {},
    updateAssinatura: ()=>{},
    linkListEstadao: {
        "Últimas" : "https://www.estadao.com.br/ultimas",
        "Brasil" : "https://brasil.estadao.com.br/",
        "Mais Lidas" : "https://www.estadao.com.br/mais-lidas",
        "Internacional" : "https://internacional.estadao.com.br/",
        "Opinião" : "https://opiniao.estadao.com.br/",
        "Esportes" : "https://esportes.estadao.com.br/",
        "Política" : "https://politica.estadao.com.br/",
        "Cultura" : "https://cultura.estadao.com.br/",
        "Economia" : "https://economia.estadao.com.br/",
    }
};

export default function config (state = INITIAL_STATE, action){
    switch (action.type) {
        case "SET_CONFIG":
            if(action.payload.hasOwnProperty('meuEstadaoMenu')){
                action.payload.meuEstadaoMenu.static = true;
            }else if(action.payload.hasOwnProperty('meuEstadaoUrl')){
                for(let i in state.meuEstadaoMenu){
                    if(state.meuEstadaoMenu.hasOwnProperty(i) && typeof state.meuEstadaoMenu[i] === "string")
                        state.meuEstadaoMenu[i] = state.meuEstadaoMenu[i].replace(state.meuEstadaoUrl, action.payload.meuEstadaoUrl);
                }
            }
            return { ...state, ...action.payload };
        default:
            return state
    }
}